import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ProfileComponent } from './examples/profile/profile.component';
import { LoginComponent } from './examples/login/login.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { LandingComponent } from './examples/landing/landing.component';
import { ComponentsComponent } from './components/components.component';
import { AboutComponent } from './examples/about/about.component';
import { MasterclassComponent } from './examples/masterclass/masterclass.component';
import { ShortfilmsdocumentariesComponent } from './examples/shortfilmsdocumentaries/shortfilmsdocumentaries.component';
import { ZddComponent } from './examples/zdd/zdd.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: ProfileComponent },
    { path: 'about', component: AboutComponent },
    { path: 'shortfilmsdocumentaries', component: ShortfilmsdocumentariesComponent },
    { path: 'masterclass', component: MasterclassComponent },
    { path: 'zdd', component: ZddComponent },
    { path: '**', component: ProfileComponent }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
